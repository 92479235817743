import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { MemberTransferBalanceRequestType } from '../../Models/MemberAccount/MemberTransferBalanceRequest';
import { MemberBalanceResponse } from '../../Types/MemberAccount/MemberBalanceResponse';

export const initialState: MemberBalanceResponse = {
  totalAssests: '',
  mainWallet: '',
  supplierBalanceDetails: []
};

export const WalletSlice = createSlice({
  name: 'Wallet',
  initialState: initialState,
  reducers: {
    setWalletData: (state, action: PayloadAction<MemberBalanceResponse>) => {
      state.totalAssests = action.payload.totalAssests;
      state.mainWallet = action.payload.mainWallet;
      state.supplierBalanceDetails = action.payload.supplierBalanceDetails;
    },
  }
});

export const { setWalletData } = WalletSlice.actions;

export default WalletSlice.reducer;