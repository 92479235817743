import { ReportHandler } from 'web-vitals';

// 這是一個用於報告網頁性能指標的函數
const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  // 檢查是否提供了有效的性能報告處理函數
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // 動態導入 web-vitals 庫
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      // 測量並報告核心網頁性能指標
      getCLS(onPerfEntry);  // Cumulative Layout Shift
      getFID(onPerfEntry);  // First Input Delay
      getFCP(onPerfEntry);  // First Contentful Paint
      getLCP(onPerfEntry);  // Largest Contentful Paint
      getTTFB(onPerfEntry); // Time to First Byte
    });
  }
};

// 導出函數以供其他模塊使用
export default reportWebVitals;
