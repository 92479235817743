import { useEffect, useState } from "react";
import { debounce } from "../Utils";

const useOrientation = () => {
  const [orientation, setOrientation] = useState(window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape');
  
  useEffect(() => {
    const handleResize = debounce(() => {
      window.location.reload();
    }, 100);
    const portraitMediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = (e: any) => {
      setOrientation(e.matches ? 'portrait' : 'landscape');
      handleResize();
    };

    portraitMediaQuery.addEventListener('change', handleOrientationChange);

    return () => {
      portraitMediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);

  return orientation;
};

export default useOrientation;