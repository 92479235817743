import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const initialState = {
  vipInformationStore:{}
};

export const VipInformationSlice :any = createSlice({
  name: 'brands',
  initialState: initialState,
  reducers: {
    setVipInformation:(state, action)=>{
      state.vipInformationStore = action.payload
    },
  }
})
export const { setVipInformation } = VipInformationSlice.actions;

export default VipInformationSlice.reducer;