import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// 定義一個介面，描述站內信狀態的結構
interface SiteMessageState {
  unreadMessagesCount: number; // 未讀訊息數量
}

// 設定初始狀態，未讀訊息數量預設為 0
export const initialState: SiteMessageState = {
  unreadMessagesCount: 0,
};

// 使用 createSlice 來建立一個 Redux 切片
export const SiteMessageSlice = createSlice({
  name: 'SiteMessage', // 切片的名稱
  initialState: initialState, // 初始狀態
  reducers: {
    // 定義一個 action，用來設定未讀訊息數量
    setSiteMessageData: (state, action: PayloadAction<number>) => {
      state.unreadMessagesCount = action.payload; // 將 action 的 payload 設定為未讀訊息數量
    },
  }
});

// 從切片中解構出 action
export const { setSiteMessageData } = SiteMessageSlice.actions;

// 將切片的 reducer 作為預設匯出
export default SiteMessageSlice.reducer;