
export const DomainExtractor = (url: string) => {
  if (url.includes('localhost')) {
    return 'spark99';
  }
  const regex = /^(?:https?:\/\/)?(?:dev\.|stage\.|www\.|asia\.|devm\.|stagem\.|m\.)?([^\/?#]+)\.(?:com|io)/;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return '';
  }
}

export async function CopyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export function GetCurrentLanguage() {
  return localStorage.getItem('language')
}

export function GetCurrentToken() {
  return localStorage.getItem('access_token')
}

export function GetCurrentTenant() {
  localStorage.getItem("tenant")
}

export function GetFormattedDate(date:Date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

// 解決safari無法打開新分頁的問題
export function WindowOpen(url: string, type: string) {
  setTimeout(() => {
    window.open(url, type);
  })
}

export function WindowOpen2iOS(url: string, type: string, delay: number = 0) {
  setTimeout(() => {
    window.open(url, type);
  }, delay);
}

// 倒數計時一分鐘
export function CountDownTimer(timestamp: any) {
  // 取得本地存儲中的時間戳
  const storedTimestamp = localStorage.getItem('storedTimestamp');
  if (storedTimestamp !== null) {
    // 如果本地存儲中有時間戳，則計算當前時間戳與本地存儲中的時間戳的差值有沒有大於60秒
    const diff = parseInt(timestamp, 10) - parseInt(storedTimestamp, 10);
    if(diff > 60000){
      localStorage.removeItem('storedTimestamp');
    }
    return diff > 600000;
  } else {
    // 如果沒有本地存儲中的時間戳，則將當前時間戳存儲到本地存儲中
    localStorage.setItem('storedTimestamp', timestamp.toString());
    return true;
  }
}

// debounce
export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function (...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

// 千分為格式
export function FormatNumber(num: number | string): string {
  // 字串型態
  if (typeof num === 'string') {
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  // 數字型態
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}