import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AnnouncementStore } from '../../Types/Announcement/AnnouncementStore';
import { AnnouncementModel, AnnouncementModelItems, AnnouncementResposne } from '../../Types/Announcement/AnnouncementResponse';

export const initialState : AnnouncementStore = {
  announcementStore:{
        Announcement:[],
        content: "",
        lastModificationTime: "",
        lastModifierId: "",
        creationTime: "",
        creatorId: "",
        id: "",
        announcementType: 0,
        title: "",
        publishTime: "",
        status: true,
        tenantId: "",
        announcementImageData:"",
        announcementURL: "",
        homePopup: true
      }
};

export const AnnouncementSlice :any = createSlice({
  name: 'announcement',
  initialState: initialState,
  reducers: {
    announcementStore:(state, dispatch: PayloadAction<AnnouncementResposne[]>)=>{
      state.announcementStore.Announcement = dispatch.payload
    },
  }
})
export const { announcementStore } = AnnouncementSlice.actions

export default AnnouncementSlice.reducer