import { AnnouncementSlice } from './Slices/AnnouncementSlice';
import { configureStore } from '@reduxjs/toolkit'
import { BrandsSlice } from './Slices/BrandsSlice';
import { WalletSlice } from './Slices/WalletSlice'
import { VipInformationSlice } from './Slices/VipInformationSlice';
import { PlayerWalletInfoSlice } from './Slices/PlayerWalletInfoSlice';
import { SiteMessageSlice } from './Slices/SiteMessageSlice';
import { HomeBannerSlice } from './Slices/HomeBannerSlice';
// import {CommonSlice} from "../Crud/Common/CommonSlice";
// import {NewsSlice } from '../Crud/News/NewsSlice';
// import { GameSupplierSlice } from '../Crud/GameSupplier/GameSupplierSlice';

export const store = configureStore({
    reducer: {
      brands: BrandsSlice.reducer,
      wallet: WalletSlice.reducer,
      // common: CommonSlice.reducer,
      // news: NewsSlice.reducer,
      // gameSupplier:GameSupplierSlice.reducer,
      announcement : AnnouncementSlice.reducer,
      vipInformation: VipInformationSlice.reducer,
      playerWalletInfo: PlayerWalletInfoSlice.reducer,
      siteMessage: SiteMessageSlice.reducer,
      homeBanner: HomeBannerSlice.reducer
    }
  })

export{}