import React, { Suspense, useEffect, useState, lazy, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
import LoadingPage from './Pages/PcPages/LoadingPage/LoadingPage';
import { debounce } from './Utils';
import useOrientation from './Hooks/useOrientation';
import { I18nextProvider } from 'react-i18next';
import i18n from "./i18n/config";
import { use } from 'i18next';
// 記錄使用者資訊
import { FpjsProvider /*, FingerprintJSPro */ } from '@fingerprintjs/fingerprintjs-pro-react'

const RootComponent = () => {

  //查看是水平還是垂直
  useOrientation();
 
  const [isMobile, setIsMobile] = useState(false);

  // 檢測設備類型
  const detectDeviceType = useCallback(() => {
    const isMobileDevice = /Mobi/i.test(navigator.userAgent);
    if (window.innerWidth < 768 && isMobileDevice) {
      setIsMobile(true);
    }
  },[]);

  // 檢驗設備調整畫面
  useEffect(() => {
    detectDeviceType();
    window.addEventListener("resize", detectDeviceType);
    return () => {
      window.removeEventListener("resize", detectDeviceType);
    };
  }, [detectDeviceType]);
  // 根據設備類型判斷要加載的應用組件。如果是移動設備，則懶加載移動版應用（AppMobile），否則加載桌面版應用（App）。
  const App = isMobile ? lazy(() => import("./AppMobile")) : lazy(() => import("./App"));

  return (

    // 這段代碼是React應用的主要結構,它做了以下幾件事:
    // 1. 使用React.StrictMode來啟用嚴格模式,有助於發現潛在問題。
    // 2. 使用Provider組件將Redux store提供給整個應用。
    // 3. 使用I18nextProvider來提供國際化功能。
    // 4. 使用Suspense組件來處理懶加載,並在加載時顯示LoadingPage。
    // 5. 使用FpjsProvider來集成FingerprintJS Pro,這是一個用於識別瀏覽器和設備的服務。
    // 6. 最後渲染App組件,這可能是移動版或桌面版,取決於之前的設備檢測結果。


    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Suspense fallback={<LoadingPage />}>
            <FpjsProvider
              loadOptions={{
                apiKey: 'SubhMxSiHgRMQ1Ulkz1i',
                // region: 'eu',
                // endpoint: ['metrics.yourwebsite.com', FingerprintJSPro.defaultEndpoint],
                // scriptUrlPattern: ['metrics.yourwebsite.com/agent-path', FingerprintJSPro.defaultScriptUrlPattern],
              }}
            >
              <App />
            </FpjsProvider>
          </Suspense>
        </I18nextProvider>
      </Provider>
    </React.StrictMode>
  );

}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RootComponent />);

reportWebVitals();
