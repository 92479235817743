import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export const initialState = {
  brandsStore:[]
};

export const BrandsSlice :any = createSlice({
  name: 'brands',
  initialState: initialState,
  reducers: {
    brandsStore:(state, action)=>{
      state.brandsStore = action.payload
    },
  }
})
export const { brandsStore } = BrandsSlice.actions;

export default BrandsSlice.reducer;