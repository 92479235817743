import LoadingBlock from '../../../Components/Loading/LoadingBlock';
import './LoadingPage.scss';

const LoadingPage = () => {
  return (
    <>
      <section className='custom-loading-page'>
        <LoadingBlock />
      </section>
    </>
  );
}
 
export default LoadingPage;