import * as React from 'react';
import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingBlock = () => {
  return (
    <>
      <section className='text-center'>
        <React.Fragment>
          <svg width={0} height={0}>
            <defs>
              <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#92E4EB" />
                <stop offset="100%" stopColor="#0B7F96" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
      </section>
    </>
  );
}
 
export default LoadingBlock;