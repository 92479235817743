import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "zh-Hant",
  lng: "zh-Hant",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    "zh-Hant": {
      translations: require("./locales/zh-Hant/translations.json"),
    },
    "zh-Hans": {
      translations: require("./locales/zh-Hans/translations.json"),
    },
    hi: {
      translations: require("./locales/hi/translations.json"),
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "zh-Hant", "zh-Hans", "hi"];

export default i18n;
