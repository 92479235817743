import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// types
import { CampaignModel } from "../../Types/CampaignResposne";

interface HomeBannerState {
  banners: CampaignModel[];
}

export const initialState: HomeBannerState = {
  banners:[],
};

export const HomeBannerSlice = createSlice({
  name: 'HomeBanner',
  initialState: initialState,
  reducers: {
    setBanners: (state, action) => {
      state.banners = action.payload;
    },
  }
});

export const { setBanners } = HomeBannerSlice.actions;

export default HomeBannerSlice.reducer;