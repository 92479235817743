import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlayerWalletInfoType } from '../../Types/MemberAccount/MemberBalanceResponse';

export const initialState: PlayerWalletInfoType = {
  mainWallet: '',
  playerLevelInfo: null
};

export const PlayerWalletInfoSlice = createSlice({
  name: 'PlayerWalletInfo',
  initialState: initialState,
  reducers: {
    setPlayerWalletInfoData: (state, action: PayloadAction<PlayerWalletInfoType>) => {
      state.mainWallet = action.payload.mainWallet;
      state.playerLevelInfo = action.payload.playerLevelInfo;
    },
  }
});

export const { setPlayerWalletInfoData } = PlayerWalletInfoSlice.actions;

export default PlayerWalletInfoSlice.reducer;